import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { AllContentfulSaleQuery } from '../../../types/gatsby-graphql'
import { NFTCard } from '../../components/NFTCard/NFTCard'
import { SharedLayout } from '../../components/SharedLayout/SharedLayout'
import { Capitalize } from '../../utils/string'
import * as styles from './Collections.module.scss'
import loadable from '@loadable/component'
import { enricheLotWithAuction } from '../../utils/auction'
import { CollectionContentfulSale } from '../../../types/pages/collections'
import { FilterComponent } from '../../components/FilterComponent/FilterComponent'

const LoadableSaleMonitor = loadable(
  () => import('../../components/GalleryMonitor/GalleryMonitor')
)

const Collection = ({ data }: { data: CollectionContentfulSale }) => {
  const [lots, setLots] = useState<any[]>([])
  const [auctionIds, setAuctionIds] = useState<string[]>([])

  useEffect(() => {
    const lotsWithAuctions = data?.lots?.filter((lot: any) => lot?.auction !== null)
    const auctionIds = lotsWithAuctions?.map((lot: any) => lot?.auction?.contentfulid)

    setAuctionIds(auctionIds as string[])
    setLots(lotsWithAuctions as any[])
  }, [data])

  if ( lots.length === 0 ) {
    return null
  }

  return (
    <div className={styles.collectionsFoundationList}>
      <Link to={`/collection/${data.contentful_id}`}>
        <h1>{Capitalize(data.name!)}</h1>
      </Link>
      <LoadableSaleMonitor
        auctions={auctionIds}
        render={({ result }) => {
          const enrichedData = enricheLotWithAuction(
            lots || [],
            result?.auctions
          )
          return (
            <div className={styles.collectionsNFTList}>
              {enrichedData.map((data: any) => {
                if ( !data?.lot?.auction ) {
                  return null
                }
                return (
                  <NFTCard
                    title={data?.lot?.auction?.mint?.name!}
                    assetType={
                      data?.lot?.auction?.mint?.mediaAsset?.media?.mimeType!
                    }
                    imgUrl={
                      data?.lot?.auction?.mint?.mediaAsset?.media?.file?.url!
                    }
                    price={data?.auction?.reservePriceInETH!}
                    by={`${data?.lot?.artist?.firstName!} ${data?.lot?.artist
                      ?.lastName!}`}
                    ownedBy={data?.auction?.seller?.username!}
                    linkTo={`/artwork/${data?.lot?.contentful_id}`}
                  />
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}

const Collections = () => {
  const data: AllContentfulSaleQuery = useStaticQuery(graphql`
      query AllContentfulSale {
          allContentfulSale {
              edges {
                  node {
                      slug
                      name
                      contentful_id
                      lots {
                          lotId
                          ended
                          contentful_id
                          artist {
                              ... on ContentfulArtistSignup {
                                  firstName
                                  middleName
                                  lastName
                                  username
                                  contentful_id
                              }
                          }
                          auction {
                              ...lotAuctionFragment
                          }
                      }
                      startDate
                  }
              }
          }
      }
  `)

  return (
    <SharedLayout
      title="Collections"
      blueCenter={false}
      body={
        <>
          <div className={styles.collectionsContainer}>
            <FilterComponent/>
            {data.allContentfulSale.edges.map(item => {
              return <Collection data={item.node}/>
            })}
          </div>
        </>
      }
    />
  )
}

export default Collections
